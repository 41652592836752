@import '../../variables';
.mod_bb_share_modal {
  .modal-body {
    .share-btns {
      list-style: none;
      padding: 0;
      li {
        padding: 0;
        display: inline-block;
        a {
          display: block;
          color: $dacomgreen;
          width: 80px;
          height: 80px;
          background-color: $dacomyellow;
          text-align: center;
          line-height: 80px;
          border-radius: 50%;
          margin: 0 4px;
          padding-top: 0;
          font-size: 60px;
          &:hover {
            transform: scale(1.2);
            background-color: $white;
          }
        }
      }
    }
  }
}