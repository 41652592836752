/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */
@import '../../variables';

.mod_bb_map {
  .showSearch .mod_bb_quicksearch {
    display: block
  }

  .mod_bb_quicksearch {
    width: 100%;
    padding: 0 $gutter*2 0 0;
    display: none;
    z-index: 900;

    >div {
      position: relative;
      top: 10px;
      padding: $gutter / 2;

      &:after {
        position: absolute;
        top: -10px;
        left: 35px;
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent $white transparent;
      }

      background-color: $white;

      form {
        .form-group {
          position: relative;

          input {
            background: url("../../img/icons/icon_search.svg") no-repeat center left $gutter / 4 lighten($black, 90%);
            border: 0;
            box-shadow: none;
            height: 50px;
            line-height: 50px;
            padding-left: $gutter;
            padding-right: 50px;
            border-radius: 5px;
            width: 100%;

            &:focus {
              outline: none;
            }

            &::-webkit-input-placeholder, &:-moz-placeholder, &::-moz-placeholder, &:-ms-input-placeholder {
              color: lighten($black, 60%);
            }
          }
        }
      }
    }
  }


  .geosuggest {
    font-size: 0.8rem;
    position: relative;
    margin: 8px auto;
    text-align: left;
  }

  .geosuggest__input {
    width: 100%;
    border: 2px solid transparent;
    box-shadow: 0 0 1px #3d464d;
    padding: .5em 1em;
    -webkit-transition: border 0.2s, box-shadow 0.2s;
    transition: border 0.2s, box-shadow 0.2s;

    &:focus {
      border-color: #267dc0;
      box-shadow: 0 0 0 transparent;
    }
  }

  .geosuggest__suggests {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 25em;
    padding: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    border-top-width: 0;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
    z-index: 5;
    -webkit-transition: max-height 0.2s, border 0.2s;
    transition: max-height 0.2s, border 0.2s;
  }

  .geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
  }

  .geosuggest__item {
    font-size: 0.8em;
    padding: .5em .65em;
    cursor: pointer;

    &:before {
      background-image: url('../../img/leaflet/marker-icon.png');
      background-size: 12px 20px;
      display: inline-block;
      width: 12px;
      height: 20px;
      content: "";
      float: left;
      margin-right: 10px;
    }

    &:hover, &:focus {
      background: #f5f5f5;
    }
  }

  .geosuggest__item--active {
    background: #267dc0;
    color: #fff;

    &:hover, &:focus {
      background: #ccc;
    }
  }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: map-get($grid-breakpoints, "md")) {

  .mod_bb_map .mod_bb_quicksearch {
    display: block;
    top: $headerHeight-xs;
    height: $datasetsHeight-xs;
    padding-left: $asideNavWidth-sm;
    transition: left $animationSpeed ease-in-out;
    max-width: $mainPanelWidth;

    >div {
      position: relative;
      top:0;
      padding: 0;
      width: 100%;
      background-color: transparent;

      &:after {
        display: none;
      }

      form {
        .form-group {
          margin-bottom: 0;

          input {
            height: 28px;
            line-height: 28px;
            padding-right: 28px;
            background-color: $white;
            color: #555;
          }
        }
      }
    }
  }

  body.main-panel-open {
    .mod_bb_quicksearch {
      left: $asideNavWidth-sm + $mainPanelWidth;
      display: none !important;
    }
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: map-get($grid-breakpoints, "lg")) {
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: map-get($grid-breakpoints, "xl")) {
}