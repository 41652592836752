@import '../../../variables';
.mod_bb_water {
  .seperation {
    background-color: grey !important;
    width: 100%;
    height: 1px;
    opacity: 0.3;
    margin-bottom: 10px;
  }
  .bold_text {
    font-weight: bold;
  }

  .fromTop {
    position: relative;
    top: 10%
  }

  .highlighted {
    background-color: $dacomyellow;
    opacity: 1;
  }
}