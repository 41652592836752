@import './../../../variables.scss';


.c-hero{
    background-size: cover;
    background-repeat: no-repeat;
    height: inherit;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 20px;
    

    @media (max-width: map-get($grid-breakpoints, "sm")) {
        min-height: 200px;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        margin-top: -16px;
        padding: 22px 16px 16px 16px !important;
    }

    &--overlay-layer{
        &::after{
            content: '';
            position: absolute;
            top: 0;

            @media (max-width: map-get($grid-breakpoints, "sm")) {
                border-top-left-radius: 25px;
                border-top-right-radius: 25px;
            }
        }
    }

    .header--button-container{
        display: flex;
        justify-content: space-between;
        gap: 0.5rem;

        .button-group{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 4px;
            
        }

    }

    .tag-container{
        .badge{
            margin-right: 4px;
        }
    }

    .header--content {
        padding: 0px 0px 0px 0px;
        margin-top: auto;

        @media (max-width: map-get($grid-breakpoints, "sm")) {
            padding: 0px 0px 0px 0px;
        }
    }

    .header--title{
        font-family: $font-family-heading;
        font-size: $font-size-l;
        font-style: normal;
        font-weight: 600;
        color: $white;
        margin-bottom: 4px;
    }
    .header--subtitle{
        font-size: $font-size-s;
        font-weight: 700;
        color: $white;
        margin-bottom: 16px;
    }

    &__figure {
        display: flex;
        background-color: $white;
        z-index: 2;
        margin-bottom: 0;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        border: 2px solid $color-neutral-20;
        left: calc(50% - 12px);
        opacity: 0.8;

        >i{
          width: auto;
          font-size: 32px;
          margin: auto;
        }
    }

}