@import '../../../variables';

.mod_bb_ews {
  background-color: $white;
  padding: $gutter;
  button { background-color: transparent}
  .crop-table {
    cursor: pointer;
    margin-top: 10px;

    tr.active {
      font-weight: bold;
      opacity: 1;
    }

    tr.inactive {
      td.ews-value {
        opacity: 0.5;
      }
    }

    th.ews-crop-disease-name {
      width: 180px;
    }

    th.ews-date, td.ews-value {
      color: #000000;
      transition: transform .2s; /* Animation */
      vertical-align: middle;
      text-align: center;

      &.zoom {
        transform: scale(1.2);
      }

      span, div {
        padding: 4px;
        background-color: #5bc0de;
      }
    }

    td:first-child {
      width: 32px;
    }
  }

  .table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th {
    border: none;
    padding: 5px;
  }
}