@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

.mod_bb_main_panel > article.mod_bb{
  // TODO AAARGH. THIS IS NOT HOW THIS PROJECTS CSS IS STRUCTURED!!! SIDE_EFFECT_FROM_HELL
  background-color: #F1F5F4 !important;
}

.accordion {
  padding-inline: 8px;
  .btn-plus {
    padding-left: 0;
    padding-right: 0;
  }
}

.accordion__item {
  background-color: var(--white);
  margin: 0;
  box-shadow: 0 1px 0 #ECF2F2;
  border: 1px solid #ECF2F2;

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  &--is-open {
    margin: 8px 0;
  }
}

.accordion__items{
    list-style: none;
}

.accordion__item--is-open > .accordion__panel {
  display: block;
}

.accordion__panel {
  display: none;
  will-change: height;
  transform: translateZ(0px);
}

.accordion__header { /* button */
  font-family: 'Poppins';
  font-weight: 600;
  line-height: 22px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  font-size: 16px;
  color: var(--black);
  cursor: pointer;
  padding: 16px 12px;

  &--title{
    flex-wrap: nowrap;
  }

  .icon {
    --size: 20px;

    .icon__group {
      stroke-width: 2px;
    }
  }

  .icon__group{
    display: flex;
    align-items: center;
  }
}

.accordion__content--title {
  button.btn { line-height: 1}
}

.accordion__edit {
  padding-top: 0;
  padding-bottom: 0;
}

.accordion__item--is-open > .accordion__header {
  padding: 8px 12px;
  .icon__group {
  > .accordion__icon-arrow { /* animated arrow icon */
    -moz-transition: all .3s linear;
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
    transform: rotate(-180deg);


    > *:last-child {
      transform: translateY(-8px) rotate(90deg);
      -moz-transition: all .3s linear;
      -webkit-transition: all .3s linear;
      transition: all .3s linear;
    }
  }
}

  > .accordion__icon-arrow-v2 .icon__group { /* animated arrow icon v2 */
    transform: rotate(-90deg);

    > *:first-child, *:last-child {
      stroke-dashoffset: 0;
      transform: translateY(0px);
    }
  }

  > .accordion__icon-plus .icon__group { /* animated plus icon */
    transform: rotate(0);

    > *:first-child {
      transform: rotate(0);
    }
  }
}


  /* animations */
.accordion[data-animation=on] .accordion__item--is-open .accordion__panel > * {
  -webkit-animation: accordion-entry-animation 0.4s var(--ease-out);
          animation: accordion-entry-animation 0.4s var(--ease-out);
}
.accordion[data-animation=on] .accordion__icon-arrow,
.accordion[data-animation=on] .accordion__icon-arrow-v2,
.accordion[data-animation=on] .accordion__icon-plus {
  -moz-transition: all .2s linear;
  -webkit-transition: all .2s linear;
  transition: all .2s linear;  
}
.accordion[data-animation=on] .accordion__icon-arrow .icon__group > *,
.accordion[data-animation=on] .accordion__icon-arrow-v2 .icon__group > *,
.accordion[data-animation=on] .accordion__icon-plus .icon__group > * {
  transition: stroke-dashoffset 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, stroke-dashoffset 0.3s;
  transition: transform 0.3s, stroke-dashoffset 0.3s, -webkit-transform 0.3s;
  transition-timing-function: var(--ease-out);
}

@keyframes accordion-entry-animation {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}