@import '../../variables';

.ews-date-selector-wrapper {
  position: absolute;
  bottom: 25px;
  z-index: 600;
  display: flex;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);

  .date-selector-btn {
    position: relative;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    outline: none;
    cursor: pointer;
    height: 32px;
    font-size: 14px;
    padding: 0 15px;
    font-weight: 400;
    border-radius: 5px;
    border: 1px solid #dddddd;
    background-color: #ffffff;
  }
  .date-selector-btn:hover {
    background-color: #ffffff;
  }
  .date-selector-date-field {
    height: 32px;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 5px;
    position: relative;
    display: inline-block;
    width: 100px;
    margin: 0 5px;
    font-size: 14px;
    text-align: center;
    color: black;
    padding-top: 8px;
  }
  .MuiFormLabel-root.Mui-disabled {
    color: black;
  }

}

/* TODO dit is niet hoe het hoort, Mobile first is het hele design principe */
@media (max-width: map-get($grid-breakpoints, "md")) {
  .ews-date-selector-wrapper {
    position: fixed;
    bottom: 170px;
  }

}