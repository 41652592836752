@import '../../variables';

#printable-content {
  display: block;
  position: absolute;
  top: $headerHeight-xs;
  left: 0;
  width: 100%;
  overflow: auto;
  background-color: white;
  z-index: 800;
  padding: 0;
  color: #505050;
  font-size: 14px;
  table{
    tr, td{
      padding: 5px;
    }
  }
  dl, dd{
    margin: 0;
  }
  .print-width {width: 670px; margin: 0 auto;}
  .bunder_hero {margin-top: 20px}
  .hidden-print { display: none; }
  .bb_logo { margin-bottom: 16px;}
  .qr { padding-right: 5px;}
  .action-btns {
    float: right;
    display: block;
    width: 100%;
    .back-btn, .print-btn {
      display: inline-block;
      margin-right: 20px;
      width: 40px;
      height: 40px;
    }
    .back-btn {
      .btn-close {
        width: 40px;
        height: 40px;
        background-size: 57px;
      }
    }
  }
  .key {
    display: inline-block;
    width: 50%;
    vertical-align: top;
    margin-top: 20px;
  }
  .keymap {
    padding-left: 18px;
    display: inline-block;
    width: 50%;
    .legend {text-align: center}
  }
  .flag-icon {
    transform: scale(0.8);
    margin-bottom: 10px;
    &.flag-icon2 {
      margin-left: 10px;
    }
  }
  .recharts-wrapper {
    margin-left: -40px;
  }
  #dem-statistics {
    margin-top:20px;
    .map_100p {
      padding-left: 18px;
      margin-top:0;
      float:right;
      width: 49%;
    }
    .bb_selectionButtons, .bb_toggleButton {
      display: none;
    }
    .recharts-wrapper {
      float:left;
    }

  }
  #dem-summary {
    float:right;
    width: 20%;
    vertical-align: top;
  }
  .table {
    > thead > tr > th,
    > tbody > tr > th,
    > tfoot > tr > th,
    > thead > tr > td,
    > tbody > tr > td,
    > tfoot > tr > td {
      border: none;
      padding-top:0;
    }
  }
  img.dacom-logo {
    float: right;
    background-size: 60px 60px;
    width: 60px;
    height: 60px;
    position: relative;
    bottom: 30px;
  }

  #crop-history-table {
    > tbody > tr > td {
      padding-left: 0;
      line-height: 16px;
    }
    th, td {
      padding-bottom: 8px;
    }
  }


  #crop-history-table .icon-placeholder {
    width: 47px; height: 47px;
    > i {
      font-size: 35px;
    }
  }

  .unbreakable-content {
    page-break-inside: avoid;
    break-inside: avoid;
    position: relative;
    display: inline-block;
    width: 100%;
    float: none;
    .container-fluid {
      padding: 0;
    }
  }

  .map_100p {
    max-width: $breakpointPrintReport;
    margin: 20px 0;

  }

  .history-container {
    max-width: $breakpointPrintReport;
    font-size: 11px;
    padding-top: 20px;
  }

  span.color-box {
    width: 15px;
    height: 15px;
    display: inline-block;
    background-color: #FFFFFF;
    margin-right: 5px;
    float:left;
  }

  h1 {
    border-bottom: 1px solid #F5C342;
    font-size: 24px;
    margin: 24px 0 5px 0;
    padding-bottom: 15px;
    text-transform: uppercase;
  }
  h2 {
    border-bottom: 1px solid #F5C342;
    font-size: 20px;
    margin: 0 0 5px 0;
    padding-bottom: 15px;
    text-transform: uppercase;
  }
  h3 {
    font-size: 18px;
    text-transform: uppercase;
  }
  dt {
    font-weight: 500;
  }

}

@media print {
  body {scale: 1.2}
  @page {
    size: A4 landscape;
  }
  .mod_bb_header, .mod_bb_map, .mod_bb_nav_aside, .mod_bb_main_panel, .action-btns, #single_tooltip {display:none !important}
  #printable-content {
    position:relative; // page-break-inside prop only works if parents have no position:absolute
    .print-width {width: auto; margin: 15%;}
  }
  #Header, #Footer { display: none !important; }

  .color-box {
    -webkit-print-color-adjust: exact;
  }

  // Can't make it work. Printing background images is hard
  //#printable-content .flag-icon {
  //   -webkit-print-color-adjust: exact !important;
  //   color-adjust: exact !important;
  //  border: 1px #aaa outset; // make white flags on white background visible
  //}

  #printable-content:last-child {
    page-break-after: auto;
  }
}
