@import "../variables.scss";

body.bb_country_BE {
  .mod_bb_datasets .btn-group button {
    &.cadastre {
      background-image: url("../img/icons/datasets/kadaster_be.svg");
    }
    &.property_i {
      background-image: url("../img/icons/datasets/overheid_be.svg");
      background-size: 85% 85%;
    }
    &.vha {
      background-image: url("../img/icons/datasets/tint.svg");
      background-size: 75% 75%;
    }
    &.nitrate_area_2025 {
      background-image: url("../img/icons/datasets/nitrate_area_type_2025.svg");
      background-size: 75% 75%;
    }
    &.nitrate_area_2023 {
      background-image: url("../img/icons/datasets/nitrate_area_type_2023.svg");
      background-size: 75% 75%;
    }
    &.nitrate_area_2021 {
      background-image: url("../img/icons/datasets/nitrate_area_type_2021.svg");
      background-size: 75% 75%;
    }
  }
  .mod_bb_report .field-attributes {
    .state-img {
      background-image: url("../img/icon_be_state.svg");
    }
    .nitrate-img {
      line-height: 68px;
      font-weight: bold;
      font-family: sans-serif;
      padding-right: 10px;
      color: black;
    }
    .nitrate-img-2025 {
      opacity: 0.4;
      &::before {
        content: "2025";
        position: relative;
        top: 14px;
        left: 5px;
      }
    }
    .nitrate-img-2023 {
      opacity: 0.4;
      &::before {
        content: "2023";
        position: relative;
        top: 14px;
        left: 5px;
      }
    }
    .nitrate-img-2021 {
      opacity: 0.4;
      &::before {
        content: "2021";
        position: relative;
        top: 14px;
        left: 5px;
      }
    }
  }
  .mod_bb_tabs ul.nav > li a > i {
    &.icon_property {
      background-image: url("../img/icons/icon_cadastre_be.svg");
    }
  }
}

body.bb_country_DK {
  .mod_bb_datasets .btn-group button {
    &.cadastre {
      background-image: url("../img/icons/datasets/kadaster_dk.svg");
    }
    &.organic {
      background-image: url("../img/icons/datasets/organic_dk.png");
      background-size: 85% 85%;
    }
    &.gwp {
      background-image: url("../img/icons/datasets/gwp_dk.png");
      background-size: 85% 85%;
    }
  }
  .mod_bb_tabs ul.nav > li a > i.icon_property {
    background-image: url("../img/icons/icon_cadastre_dk.svg");
  }
  .mod_bb_report .field-attributes {
    .gwp-img {
      background-image: url("../img/icon_gwp_dk.png");
    }
    .organic-img {
      background-image: url("../img/icon_organic_dk.png");
    }
  }
}
body.bb_country_FR {
  .mod_bb_datasets .btn-group button {
    &.organic {
      background-image: url("../img/icons/datasets/organic_fr.svg");
      background-size: 85% 85%;
    }
  }
  .mod_bb_report .field-attributes {
    .organic-img {
      background-image: url("../img/icon_organic_fr.svg");
    }
  }
}

body.bb_country_WA {
  .mod_bb_datasets .btn-group button {
    &.cadastre {
      background-image: url("../img/icons/datasets/cadastre_wa.svg");
    }
  }
  .mod_bb_tabs ul.nav > li a > i {
    &.icon_property {
      background-image: url("../img/icons/icon_cadastre_wa.svg");
    }
  }
}

body.bb_country_DE_NW,
body.bb_country_DE_NI,
body.bb_country_DE_SH {
  .history-container {
    display: inline-block;
  }
  .field-attributes {
    display: inline-block;
    vertical-align: top;
    h3 {
      display: none;
    }
  }
  .mod_bb_datasets .btn-group button {
    &.gwp {
      background-image: url("../img/icons/datasets/gwp_de.svg");
      background-size: 70% 70%;
    }
    &.cadastre {
      background-image: url("../img/icons/datasets/cadastre_de.svg");
    }
  }
  .mod_bb_report .field-attributes {
    .gwp-img {
      background-image: url("../img/icon_gwp_de.svg");
      line-height: 10px;
      font-weight: bold;
      font-family: sans-serif;
      color: black;
    }
  }
  .mod_bb_tabs ul.nav > li a > i {
    &.icon_property {
      background-image: url("../img/icons/icon_cadastre_de.svg");
    }
  }
}
body.bb_country_DE_SH {
  .mod_bb_nav_aside ul.nav > .btn-aside.ifilter {
    display: none !important;
  }
  .history-container {
    display: none;
  }
}
body.bb_country_AT {
  .flag-icon {
    max-height: none;
  }
  .field-attributes {
    display: inline-block;
    vertical-align: top;
    h3 {
      display: none;
    }
  }
  .mod_bb_datasets .btn-group button {
    &.gwp {
      background-image: url("../img/icons/datasets/gwp_at.svg");
      background-size: 80% 80%;
    }
  }
  .mod_bb_report .field-attributes {
    .gwp-img {
      background-image: url("../img/icon_gwp_at.svg");
      line-height: 10px;
      font-weight: bold;
      font-family: sans-serif;
      color: black;
    }
  }
}
body.bb_country_LU {
  .crop-rotation {
    display: none;
  }
  .history-container {
    display: none;
  }
  .field-attributes {
    display: inline-block;
    vertical-align: top;
    h3 {
      display: none;
    }
  }
  .mod_bb_datasets .btn-group button {
    &.cadastre {
      background-image: url("../img/icons/datasets/government_lu.svg");
    }
  }
  .mod_bb_tabs ul.nav > li a > i {
    &.icon_property {
      background-image: url("../img/icons/icon_gov_lu.svg");
    }
  }
  .mod_bb_report .bunder_hero .inner .flag-icon2 {
    @media (min-width: 768px) {
      top: 75px;
    }
  }
  #printable-content .flag-icon1 {
    top: 40px;
  }
}
body.bb_country___ {
  /* No country switch / display */
  .bb_country_select {
    display: none !important;
  }
  .cropfields-dropdown img.country {
    display: none;
  }

  /* skip sidebar stuff */
  .mod_bb_nav_aside {
    color: black;
    background-color: white;
    ul.nav > .btn-aside {
      background-color: white;
    }

    ul.nav > .btn-aside.ifilter {
      display: none !important;
    }
    .nav.corner {
      display: none;
    }
  }

  /* So many hacks, it would make sense to replace the Header component with a specific __ version */
  /* Also, add language selector */
  .mod_bb_header {
    &.navbar {
      background-color: #202121;
      font-family: Poppins, sans-serif;
      color: white;
      font-size: 14px;

      .navbar-nav > li > a {
        color: white;

        .icn_farm {
          display: none;
        }
      }
      .navbar-toggler {
        background-color: white;
      }
      .collapse.show,
      .collapsing {
        background: black;
      }
    }
    .nav-item.bb_header_contact {
      display: none;
    }
  }
  .label-info {
    background-color: #212529;
    color: #f1f5f4;
  }
  // not sure, completely remove the sidebar?
  .mod_bb_nav_aside {
    display: none;
  }
  @media (min-width: 768px) {
    .mod_bb_main_panel {
      padding-left: 0;
      width: 430px;
    }
    .section_page .container_page {
      left: 0;
    }
    .mod_bb_map .mod_bb_quicksearch {
      padding-left: 0;
    }
  }
  @media (max-width: 768px) {
    .bunder_tabs_affix .sticky {
      padding-top: 56px; // ugly way, not sure how to fix this properly with hidden #mod_bb_datasets
    }
  }

  div .thumbnails {
    @media (min-width: map-get($grid-breakpoints, "md")) {
      left: $mainPanelWidth + 15px;
      width: calc(100vw - #{$mainPanelWidth} - 80px);
    }
  }
}
