@import './../../../../variables.scss';

$block: 'c-bunder-list-item';
.#{$block} {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    padding: 12px 16px;
    gap: 0.75rem 0.75rem;
    border-top: 1px solid #ECF2F2;

    &:hover{
        background-color: $color-neutral-30;
        text-decoration: none;
        color: inherit;
    }

    &--hover {
        background-color: $color-neutral-30;
        text-decoration: none;
        color: inherit;
    }

    &__content{
        flex-grow: 1;
        max-width: 228px;;
    }

    &__image{
        img {
            width: 48px;
            height: 48px;
            object-fit: contain;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: black;
        margin-left: auto;
        align-content: flex-start;
        overflow-wrap: break-word;
    }

    &__danger {
        color: $color-alert-70;
        border: none;

        &:hover {
            color: $color-alert-70;
            box-shadow: none;
        }
        &:focus {
            color: $color-alert-70;
            box-shadow: none;
        }
        &:active {
            color: $color-alert-70;
            box-shadow: none;
        }
    }
}
