@import "variables";
@font-face {
  font-family: 'fontello';
  src: url('#{$fontPath}/fontello.eot');
  src: url('#{$fontPath}/fontello.eot#iefix') format('embedded-opentype'),
       url('#{$fontPath}/fontello.woff2') format('woff2'),
       url('#{$fontPath}/fontello.woff') format('woff'),
       url('#{$fontPath}/fontello.ttf') format('truetype'),
       url('#{$fontPath}/fontello.svg#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('#{$fontPath}/fontello.svg?1750670#fontello') format('svg');
  }
}
*/
 
 [class^="icn_"]:before, [class*=" icn_"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  font-size: 18px;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icn_add:before { content: '\e800'; } /* '' */
.icn_apps:before { content: '\e801'; } /* '' */
.icn_arrow-e:before { content: '\e802'; } /* '' */
.icn_arrow-n:before { content: '\e803'; } /* '' */
.icn_arrow-s:before { content: '\e804'; } /* '' */
.icn_arrow-small-e:before { content: '\e805'; } /* '' */
.icn_arrow-small-n:before { content: '\e806'; } /* '' */
.icn_arrow-small-s:before { content: '\e807'; } /* '' */
.icn_arrow-small-w:before { content: '\e808'; } /* '' */
.icn_arrow-w:before { content: '\e809'; } /* '' */
.icn_bug:before { content: '\e80a'; } /* '' */
.icn_chart-line:before { content: '\e80b'; } /* '' */
.icn_compare:before { content: '\e80c'; } /* '' */
.icn_cross:before { content: '\e80d'; } /* '' */
.icn_deselect:before { content: '\e80e'; } /* '' */
.icn_drone:before { content: '\e80f'; } /* '' */
.icn_edit:before { content: '\e810'; } /* '' */
.icn_eye:before { content: '\e811'; } /* '' */
.icn_farm:before { content: '\e812'; } /* '' */
.icn_fertilize:before { content: '\e813'; } /* '' */
.icn_growth:before { content: '\e814'; } /* '' */
.icn_harvest:before { content: '\e815'; } /* '' */
.icn_height:before { content: '\e816'; } /* '' */
.icn_irrigation:before { content: '\e817'; } /* '' */
.icn_lock:before { content: '\e819'; } /* '' */
.icn_logout:before { content: '\e81a'; } /* '' */
.icn_map:before { content: '\e81b'; } /* '' */
.icn_menu:before { content: '\e81c'; } /* '' */
.icn_minus:before { content: '\e81d'; } /* '' */
.icn_notifications:before { content: '\e81e'; } /* '' */
.icn_photo:before { content: '\e81f'; } /* '' */
.icn_plan:before { content: '\e820'; } /* '' */
.icn_plant:before { content: '\e821'; } /* '' */
.icn_plots:before { content: '\e822'; } /* '' */
.icn_protection:before { content: '\e823'; } /* '' */
.icn_search:before { content: '\e824'; } /* '' */
.icn_settings:before { content: '\e825'; } /* '' */
.icn_share:before { content: '\e826'; } /* '' */
.icn_soil-scan:before { content: '\e827'; } /* '' */
.icn_soil-type:before { content: '\e828'; } /* '' */
.icn_tillage:before { content: '\e829'; } /* '' */
.icn_trash:before { content: '\e82a'; } /* '' */
.icn_weather-cloudy:before { content: '\e82b'; } /* '' */
.icn_weather-fog:before { content: '\e82c'; } /* '' */
.icn_weather-glazed-frost:before { content: '\e82d'; } /* '' */
.icn_weather-hail:before { content: '\e82e'; } /* '' */
.icn_weather-rain-heavy:before { content: '\e82f'; } /* '' */
.icn_weather-rain-light:before { content: '\e830'; } /* '' */
.icn_weather-snow:before { content: '\e831'; } /* '' */
.icn_weather-sun:before { content: '\e832'; } /* '' */
.icn_weather:before { content: '\e833'; } /* '' */
.icn_tick:before { content: '\e834'; } /* '' */
.basket:before { content: '\e835'; } /* '' */
.icn_language:before { content: '\e836'; } /* '' */
.icn_horn:before { content: '\e837'; } /* '' */
.icn_info:before { content: '\e838'; } /* '' */
.icn_tip-e:before { content: '\e83c'; } /* '' */
.icn_tip-n:before { content: '\e83d'; } /* '' */
.icn_tip-s:before { content: '\e83e'; } /* '' */
.icn_tip-w:before { content: '\e83f'; } /* '' */
.icn_android:before { content: '\e840'; } /* '' */
.icn_apple:before { content: '\e841'; } /* '' */
.icn_pin:before { content: '\e842'; } /* '' */
.icn_user:before { content: '\e843'; } /* '' */
.icn_route:before { content: '\e844'; } /* '' */
.icn_overlap:before { content: '\e846'; } /* '' */