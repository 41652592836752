.soiltriangle {
  path {
    stroke: white;
  }
  .selected path {
    stroke: black;
  }
  text {
    font-family: sans-serif;
    font-size: 11px;
    text-anchor: middle;
  }
  .selected text {
    font-weight: bold;
  }
}
.selectable .soiltype { cursor: pointer; }

.st-triangle { display: inline-block; }

#soil-triangle {
  margin: 10px 0 30px 0;
}

#soil-triangle svg {
  margin-top: 20px;
}
