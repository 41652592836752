@import "../variables";
button {
  box-shadow: none;
  border: 0;
  background-color: Red;

  transition: background .2s ease-in-out;

  &:focus {
    outline: none !important;
  }

  &.inline-link {
    background-color: transparent;
    &:hover {
      color: $blue;
    }
  }

  &.measure{
    background: url("../../img/icons/icon_measure.svg") no-repeat center center;
  }

  &.btn-deselect-likes, &.btn-add-likes {
    background-color: transparent;
    border: none;
    color:  $blue;
    margin: 10px 0px 0px 10px;
    span {
      margin-right: 5px;
    }
    &:hover, &:active, &:focus {
      background-color: transparent;
      color:  $blue;
    }
  }
  &.btn-deselect-likes {
    margin-bottom: 20px;
  }
}
button, a {
  &.btn-favorite {
    width: 25px;
    height: 25px;
    background: url("../../img/icons/icon_favorite.svg") no-repeat center center;

    &:active, &:hover, &.active {
      background: url("../../img/icons/icon_favorite_hover.svg") no-repeat center center;
    }

  }

   &.btn-print {
    width: 40px;
    height: 40px;
    display: block;
    padding: 0;
    background-color: transparent;
    background: url("../../img/icons/icon_print.png") no-repeat center center;
    background-size: 40px 40px;
    text-indent: -999999px;
  }

  &.btn-close {
    width: 40px;
    height: 40px;
    display: inline-block;
    padding: 0;
    margin: 5px 10px;
    float: right;
    background-color: transparent;
    background: url("../../img/icons/icon_close.svg") no-repeat center center;
    background-size: 40px;
    text-indent: -999999px;
  }

   &.btn-back {
    width: 40px;
    height: 40px;
    display: inline-block;
    padding: 0;
    margin: 5px 10px;
    background-color: transparent;
    background: url("../../img/icons/icon_backArrow.svg") no-repeat center center;
    background-size: 30px;
    text-indent: -999999px;
  }
  &.btn{
    line-height: 1.2;
  }
}

a.inline-link {
  color: $blue;
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
  &.active {
    background-color: #505050;
    color: #fff;
  }
  &:hover {
    background-color: #e6e6e6;
    border-color: #adadad;
  }
}
.btn-sm, .btn-group-sm > .btn {
  padding: 5px 10px;
}
.btn-xs, .btn-group-xs > .btn {
  font-size: 0.7rem;
  padding: 2px 4px;
  border: none;
  &:focus {box-shadow: none}
}
.btn-group {
  .btn:focus, .btn.focus {
    box-shadow: none;
  }
}
/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

/* Small devices (tablets, 768px and up) */
@media (min-width: map-get($grid-breakpoints, "md")) {
  button {
    &.btn-favorite {
      background: url("../../img/icons/icon_favorite.svg") no-repeat center center;
      &:active, &:hover, &.active {
        background: url("../../img/icons/icon_favorite_hover.svg") no-repeat center center;
      }
      &.active:hover {
        background: url("../../img/icons/icon_favorite.svg") no-repeat center center;
      }
    }

  }
}
