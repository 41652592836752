@import '../../variables';
.mod_bb_toggle {
  margin-top: 0.4em;
  position: relative;
  display: inline-block;
  width: 48px;
  height: 22.5px;

  input {
    display: none;
    &:checked + .slider {
      background-color: $blue;
    }
    &:focus + .slider {
      box-shadow: 0 0 1px $blue;
    }
    &:checked + .slider:before {
      transform: translateX(23px);
    }
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $cream-dark;
  border-radius: 34px;
  transition: .1s ease-out;

  &:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 18px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    transition: .1s ease-out;
    border-radius: 50%;
  }
}