.font-size-1_5 { font-size: 1.5rem !important; }
.font-size-2 { font-size: 2rem !important; }
.font-size-2_2 { font-size: 2rem !important; }
.font-size-1 { font-size: 1rem !important; }
.font-size-small { font-size: .75rem !important; }

.w-30{ width: 30% }
.w-70{ width: 70% }

.cursor-pointer{
  cursor: pointer !important;
  pointer-events: all;
}