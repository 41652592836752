/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */
@import '../../variables';

body {
  .leaflet-control-container .leaflet-control-layers-toggle {
    border-radius: 4px;
    width: 30px;
    height: 30px;
    background: white url("../../img/icons/icon_mapLayer.svg") no-repeat center center;
    background-size: 24px 24px;
  }

  .leaflet-bar, .leaflet-control-layers {
    box-shadow: none;
    border: none;
    background-color: transparent;

    .leaflet-control-layers-list {
      background: #444444;
      color: white;
      padding: 6px;
      border-radius: 4px;
    }

    &.leaflet-control.enabled {
      a {
        background-color: #dddddd;
      }
    }

    a {
      &.leaflet-control-track-location {
        font-size: 18px;
      }

      &.leaflet-control-draw-measure {
        background-size: 20px 20px;
        background-image: url("../../img/icons/measure-arrow.png");
      }
    }
  }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: map-get($grid-breakpoints, "md")) {
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: map-get($grid-breakpoints, "lg")) {
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: map-get($grid-breakpoints, "xl")) {
}