@import '../../../variables';
.mod_bb_cadastre {
  .names {
    font-style: italic;
    padding-left: 10px;
  }
  .highlighted {
    background-color: $dacomyellow;
    opacity: 1;
  }
  .subtitle-icon {
    margin-right: 8px
  }
}