@import '../../../variables';

@media (min-width: map-get($grid-breakpoints, "md")) {
  .bb_my_farm {
    .myfarm-tab {
      cursor: pointer;
    }
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: map-get($grid-breakpoints, "lg")) {
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: map-get($grid-breakpoints, "xl")) {
}