/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */
@import '../../variables';

body.main-panel-open {
  .mod_bb_nav_aside {
    ul.nav {
      top: $headerHeight-xs;
    }
  }
}

.mod_bb_nav_aside {
  .nav-tabs { border-bottom: none;}
  color: white;
  ul.nav {
    width: 100%;
    position: fixed;
    top: $headerHeight-xs;
    transition: top $animationSpeed ease-in-out;
    z-index: 800;
    padding: 0;
    margin: 0;
    border-radius: 0;

    .btn-aside {
      cursor: pointer;
      &:hover {
        color: var(--bb-selected);
      }
    }
    .show_my_farm_fill {
      display: none;
    }
    > .btn-aside {
      width: $asideNavWidth-xs;
      height: $asideNavHeight-xs;
      background-color: $dacomgreen;
      font-size: 0.786em;
      text-align: center;
      border-bottom: 1px solid rgba(255,255,255,0.1);
      position: relative;
      float: left;
      border-right: 1px solid lighten($base, 60%);

      a {
        width: 100%;
        height: $asideNavHeight-xs;
        border: none;
        background-color: inherit;
        margin-top: -1px;
        span {
          color: $base;
        }
      }

      i {
        width: 60px;
        height: 60px;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding-top: 0.25rem;
        //img {width: 100%; display: block; border-radius: 50%; max-width: 100%;}
        &.extra-icon {
          width: 20px;
          float: right;
          margin: 25px;
          font-size: 16px;
          top: 4%;
          left: 58%;
          color: $yellow;
        }

      }

      span {
        display: block;
        position: absolute;
        bottom: 5px;
        left: 0;
        width: 100%;
      }

      &.active {
        background: #1e2a26;
        color: var(--bb-selected);
      }

      > ul.dropdown-menu {
        left: auto;
        right: 0;
        margin: 0;
        padding: 0;
        font-size: inherit;
        border-radius: 0;
        border: 0;

        li {
          display: block;
          float: none;
          width: 100%;
          //border-bottom: 1px solid lighten($base, 60%);
          text-align: left;
          position: relative;

          height: $asideNavHeight-xs;
          border: none;
          background-color: inherit;
          margin-top: -1px;

          i {
            top: 50%;
            // transform: translateY(-25%) !important;
            transform: none;
            left: $gutter / 4;
          }

          span {
            color: $base;
            bottom: auto;
            top: 50%;
            transform: translateY(-50%);
            padding-left: $gutter / 2 + 40px;
          }

          &:last-child {
            border-bottom: 0;
          }

        }
      }

      &:last-child {
        border-right: 0;
      }

    }

  }

  .btn-group {
    width: 100%;
    position: fixed;
    top: -$headerHeight-xs;
    transition: top $animationSpeed ease-in-out;
    z-index: 800;
  }

  .nav.corner {
    display: none;
  }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: map-get($grid-breakpoints, "md")) {
  .mod_bb_nav_aside {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: $asideNavWidth-sm;
    background-color: $dacomgreen;
    height: 100%;
    z-index: 800;
    box-shadow: -3.5px 0 7px 1px rgba($black, 0.9);

    ul.nav {
      position: relative;
      top: $headerHeight-xs;
      //padding-top: $headerHeight-xs;

      > .btn-aside {
        width: $asideNavWidth-sm;
        height: $asideNavWidth-sm;
        border: 0;
        margin-bottom: 0;
        border-right: 0;
        border-bottom: 1px solid rgba(255,255,255,0.1);
        box-sizing: border-box;

        span {
          bottom: 15px;
        }

        &.dropdown {
          display:none;
        }

        a {
          height: $asideNavWidth-sm;
          margin-top: 0;
        }

        > ul.dropdown-menu {
          min-width: $asideNavWidth-sm;
          width: auto;
          top: 0;
          left: $asideNavWidth-sm;
          box-shadow: none;

          li {
            height: $asideNavWidth-sm;
            width: $asideNavWidth-sm;
            display: inline-block;
            float: left;
            border-bottom: 0;
            i {
              width: 60px;
              height: 60px;
              display: block;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) !important;
              margin-top: -10px;

            }

            &:nth-child(n+2) {
              display: none;
            }

            span {
              display: block;
              position: absolute;
              bottom: $gutter / 4;
              left: 0;
              width: 100%;
              padding-left: 0;
              text-align: center;
              top: auto;
            }

          }
        }

      }
    }

    .nav.corner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      bottom: 0;
      left: 0;
      width: $asideNavWidth-sm;
      height: 120px;
      text-align: center;
  
      .company_logo {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
      }
    }
  }
}

////////////////////////////////////
///////////// FOUR TABS ////////////
////////////////////////////////////

/* Small devices (tablets, 768px and up) */
.mod_bb_nav_aside {
  ul.nav {
    .btn-aside {
      @media (min-width: map-get($grid-breakpoints, "md")) and (max-height: $fiveHeightTabNav) {
        &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7) {
          display: none;
        }
      }
      @media (min-width: map-get($grid-breakpoints, "md")) and (max-height: $fourHeightTabNav) {
        &:nth-child(3) {
          display: none;
        }
      }
      @media (min-width: map-get($grid-breakpoints, "md")) and (max-height: $threeHeightTabNav) {
        &:nth-child(2) {
          display: none;
        }
      }
      @media (min-width: map-get($grid-breakpoints, "md")) and (max-height: $twoHeightTabNav) {
        &:nth-child(1) {
          display: none;
        }
      }
    }
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: map-get($grid-breakpoints, "lg")) {
}

/* Large devices (large desktops, 1200 and up) */
@media (min-width: map-get($grid-breakpoints, "xl")) {
}