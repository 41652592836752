.translabel { // put text in 2 divs to make it centered vertical & horizontal https://stackoverflow.com/a/2939979/193886
  text-align: center;
  display: table;
  > div {
    display: table-cell;
    vertical-align: middle;
    > span {
      // font-size: 14px;
      padding: 2px;
      background: rgba(255, 255, 255, 0.7);
      border-radius: 2px;
    }
  }
}
