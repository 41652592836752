/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */
@import '../../variables';

.mod_bb_legend {
  &.leaflet-control {
    position: absolute;
    top: 255px;
    right: 0;
    z-index: 600;
    max-width: 300px;
  }
  button {
    float: right;
    border: 0;
    color: $white;
    padding: $grid-gutter-width / 2;
    padding-left: 28px + ( $grid-gutter-width / 2 );
    padding-right: 35px + ( $grid-gutter-width / 2 );
    background: url("../../img/icons/icon_close_no_border.svg") no-repeat left $grid-gutter-width / 2 center rgba($base, 0.8);
    width: 100%;
    text-align: left;

    &.collapsed {
      width: auto;
      margin-right: -55px;
      padding: 12px 45px;
      color: transparent;
      background: url("../../img/icons/icon_legenda.svg") no-repeat left $grid-gutter-width / 4 center rgba($base, 0.8);
    }
  }

  .legend {
    clear: both;
    background-color: rgba($base, 0.8);
    .inner_scroll {
      padding: $grid-gutter-width / 2;
      padding-top: $grid-gutter-width / 2;
      max-height: 40vh;

      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          display: block;
          width: 100%;
          margin-bottom: $grid-gutter-width / 4;
          color: $white;
          padding-left: 25px + ( $grid-gutter-width / 4 );
          position: relative;
          min-height: 27px;
          padding-top: 3.5px;

          i {
            position: absolute;
            top: 0;
            left: 0;
            width: 25px;
            height: 25px;
            border: 1px solid $white;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: map-get($grid-breakpoints, "md")) {
  .mod_bb_legend {
    &.leaflet-control {
      top: 190px;
    }
    button {
      padding-right: $grid-gutter-width / 2;
      &.collapsed {
        padding: 12px 30px;
      }
    }
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: map-get($grid-breakpoints, "lg")) {
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: map-get($grid-breakpoints, "xl")) {
}