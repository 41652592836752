@import "../../variables.scss";

.c-icon-btn{
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background: #FFFFFF;
    opacity: 0.8;
    backdrop-filter: blur(27.1828px);
    align-items: center;
    padding: 2px;
    position: relative;
    vertical-align: center;
    text-align: center;
    display: inline-block;
    padding: 0;
    margin: 0 auto;
    line-height: 0px;
    cursor: pointer;

    &:hover{
        cursor: pointer;
    }


    >i{
        font-size: 18px;
        vertical-align: center;
        text-align: center;
        display: inline-block;
        padding: 0;
        margin: 0 auto;
    }

    &--active{
        color: $color-secondary-50;
    }

}