@import './../../../variables.scss';

$block: 'c-dropdown';
.#{$block} {

    &__menu{
        list-style: none;
        padding-inline-start: 0px;
    }

    &__item{
        display: flex;
        text-decoration: none;
        color: #0079D1;
        padding: 16px 16px;
        white-space: nowrap;
        overflow: hidden;
        transition: .2s;
        flex-wrap: wrap;
        align-items: center;
        box-shadow: 0px 1px 0px $color-neutral-20;

        &--danger{
            color: $color-alert-50
            
        }
        &:hover{
            background-color: $color-neutral-20;
            cursor: pointer;
        }

        .icon {
            margin-right: 12px;
            display: block;
            width: 12px;
            height: 12px;
            right: var(--space-xxs);
        }

        .btn .btn-tertiary {
            background-color: inherit;
            color: $black;
        }

        .badge {
            margin-left: 8px;
        }
    }
}
