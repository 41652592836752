@import "variables";
/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */
@import url('https://fonts.googleapis.com/css?family=Inter');

body {
    font-family: Inter, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.41em;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    overscroll-behavior: none;
}
.no-padding-xs{
	padding-left:0;
	padding-right:0;
}
a:focus {
  text-decoration: none; // reset bootstrap
}
.container, .container-fluid {
  padding-left: 10px;
  padding-right: 10px;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: map-get($grid-breakpoints, "md")) {
  .no-padding-xs{
    padding-left: $gutter/2;
    padding-right: $gutter/2;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: map-get($grid-breakpoints, "lg")) { }

/* Large devices (large desktops, 1200px and up) */
@media (min-width: map-get($grid-breakpoints, "xl")) { }

.label-info { background-color: #233a32; color: #ffc341; }
.label-labs { background-color: $dacomyellow; color: $blue; }
.label { font-weight: 300; }
