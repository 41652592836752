// Variables
@import "variables";
@import "~bootstrap/scss/bootstrap";
// Animations
@import "animations";

// Fonts
@import "fontello";

// Layouts
@import "body";

// Components
.container {
  width: 100% !important; // hack
}

@import "_utilities";
@import "components/buttons";
@import "components/video";
@import "components/cases";
@import "components/dl";
@import "components/btn_group";
@import "components/modal";
// DEBUG
//@import "debug";
