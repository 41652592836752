/*  
    
    Dacom Bootstrap Design Library
    - Variables
    
*/
// Color system
// PATHS
$fontPath:  "fonts";
$grid-gutter-width: 20px;


/*
 * Set your font
*/
$font-family-body:  'Inter' , sans-serif;
$font-family-heading: 'Poppins', sans-serif;

/*
 * Set your font size
*/
$font-size-base:  100%;
$font-size-xxxs:  10px;
$font-size-xxs:   12px;
$font-size-xs:    14px;
$font-size-s:     16px;
$font-size-m:     18px;
$font-size-l:     20px;
$font-size-xl:    22px;


$font-size-heading-xs:    18px;
$font-size-heading-s:     20px;
$font-size-heading-m:     24px;
$font-size-heading-l:     32px;
$font-size-heading-xl:    40px;


// PRIMARY COLORS
$base: #505050;
$white: #FFFFFF;
$black: #000000;
$grey: #F0F0F0;

$lime: #E6E628;
$cream: #f0f1e5;
$cream-dark: #EAEADF;
$gray: #969696;
$blue: #139CC2;
$primary-blue: #139cc2;
$green: #006d49;
$dacomgreen: #233a32;
$dacomyellow: #ffc341;


/*
 * Primary colors
*/
$color-primary-10: #EBF4F1;
$color-primary-20: #D6E8E2;
$color-primary-30: #99C5B7;
$color-primary-40: #4C9981;
$color-primary-50: #006E4B; // Default
$color-primary-60: #00573B;
$color-primary-70: #00452F;
$color-primary-80: #233932;
$color-primary-90: #08120F;

$color-primary-tint-10: #EDFAF6;
$color-primary-tint-20: #DAF5EC;
$color-primary-tint-30: #A0DAC8;
$color-primary-tint-40: #61C1A3;
$color-primary-tint-50: #1EA77C; // Default
$color-primary-tint-60: #0A9A67;
$color-primary-tint-70: #088257;
$color-primary-tint-80: #055136;
$color-primary-tint-90: #022116;

/*
 * Secondary colors
*/
$color-secondary-10: #DBE9F7;
$color-secondary-50: #1B75D0; // Default
$color-secondary-70: #1369C1; 



/*
 * Black and white
*/
$color-black: #08120F;
$color-white: #fff;


/*
 * Neutral colors
*/
$color-neutral-10: #F6F9F9;
$color-neutral-20: #ECF2F2;
$color-neutral-30: #D8E1E4;
$color-neutral-40: #C1CDD2;
$color-neutral-50: #A9B7BA;
$color-neutral-60: #6D7679;
$color-neutral-70: #4A5858;
$color-neutral-80: #333C3C;
$color-neutral-90: #172521;



/*
 * Alert en negative colors
*/
$color-alert-10: #FFE8E5;;
$color-alert-50: #FF695B; // Default
$color-alert-70: #E95C4F; 

$red: #FF695B;

/*
 * Adjust the border-radius
 */
 $border-radius-10: 2px;
 $border-radius-20: 4px;
 $border-radius-30: 30px;
 


$box-shadow: 0 0 5px 0 lighten($base, 50%);

/*
 * Shadows
 */
 $shadow-10: 0 1px 3px rgba($color-black, 0.05);
 $shadow-20: 0 4px 12px rgba($color-black, 0.10);
 $shadow-30: 0 -2px 2px rgba(0,0,0,0.01), 
             0 1px 1px rgba(0,0,0,0.01), 
             0 2px 2px rgba(0,0,0,0.01), 
             0 4px 4px rgba(0,0,0,0.01), 
             0 8px 8px rgba(0,0,0,0.01),
             0 16px 16px rgba(0,0,0,0.05);
 $shadow-40: 0 4px 16px rgba($color-black, 0.2); //hover

// UNITS
$headerHeight-xs: 56px;
$datasetsHeight-xs: 45px;
$gutter: $grid-gutter-width;
$logoWidth: 142px;
$logoHeight: 25px;

$asideNavWidth-xs: 20%;
$asideNavHeight-xs: 70px;

$asideNavWidth-sm: 95px;


$mainPanelWidth: 430px;
$animationSpeed: 0.2s;

//Report UNITS
$bunderHero-xs: 210px;
$bunderHero-sm: 285px;

$blogArticleHero-xs: 285px;
$blogArticleHero-sm: 480px;

$tabsHeight: 70px;
$socialButtonHeight-xs: 30px;
$cropsRotation-icon: 40px;

//Farm UNITS
$avatarSize: 68px;

//HEIGHT units (navsidebar)

$twoHeightTabNav: 240px;
$threeHeightTabNav: 335px;
$fourHeightTabNav: 430px;
$fiveHeightTabNav: 525px;
$sixHeightTabNav: 620px;
$sevenHeightTabNav: 715px;

$breakpointPrintReport: 700px;

$primary: $primary-blue;

// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: (
  "yellow": $dacomyellow
);
$font-size-base: 0.875rem;

$tooltip-opacity: 1;
$tooltip-bg: $base;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
