.facet-map {
  max-width: 250px;
  max-height: 250px;
  .shape {
    stroke: black;
    stroke-width: 3;
    cursor: pointer;
  }

  .shape:hover {
    stroke: #139CC2;
    stroke-width: 10
  }

  .shape.selected {
    stroke: #139CC2;
    stroke-width: 10
  }
}