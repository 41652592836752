/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */
@import "../../variables";

.mod_bb_datasets {
  display: none;
  position: fixed;
  height: $datasetsHeight-xs;
  width: 100%;
  top: $headerHeight-xs + $asideNavHeight-xs;
  z-index: 700;

  .btn-group > .btn {
    border-radius: 50% !important;
  }

  &.showSearch {
    display: block;
  }
  &.show {
    // small devices show-toggle
    display: block;
    background-color: rgba($black, 0.6);
    .btn-group {
      display: block;
    }
  }

  .btn-group-wrapper {
    padding-top: 8px;
    overflow-x: auto;
    overflow-y: hidden;
    &::-webkit-scrollbar {
      display: none;
    }

    width: 100%;
    position: absolute;
    right: 5px;
  }

  .btn-group {
    display: none;
    height: 28px;
    padding-left: 10px;
    text-align: right;

    .btn {
      width: 28px;
      height: 28px;
      background: $base center center no-repeat;
      border: 1px solid $white;

      &.active {
        background-color: $blue;
        border: 1px solid $white !important;
      }

      &:focus {
        box-shadow: none !important;
        border: 1px solid $white !important;
      }

      &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }

      margin: 0 3px 0 2px;
      background-size: 100% 100%;

      &.no_fly_zone {
        background-image: url("../../img/icons/datasets/dronegebieden.svg");
      }

      &.bufferZone {
        background-image: url("../../img/icons/datasets/buffer_zones.svg");
        background-size: 80% 80%;
      }

      &.nature {
        background-image: url("../../img/icons/datasets/natuurgebieden.svg");
      }

      &.gwp {
        background-image: url("../../img/icons/datasets/waterwingebieden.svg");
      }

      &.water_trap {
        background-image: url("../../img/icons/datasets/watertrappen.svg");
      }

      &.organische_stoffen {
        background-image: url("../../img/icons/datasets/organischestoffen.svg");
      }

      &.parcel {
        background-image: url("../../img/icons/datasets/percelen.svg");
        background-size: 80% 80%;
      }
      &.bparcel,
      &.lease {
        background-image: url("../../img/icons/datasets/plots.svg");
        background-size: 60% 60%;
      }

      &.soil {
        background-image: url("../../img/icons/datasets/grondsoorten.svg");
        background-size: 80% 80%;
      }

      &.r_soil {
        background-image: url("../../img/icons/datasets/grondsoort_mestwet.svg");
        background-size: 80% 80%;
      }

      &.permanent_grass {
        background-image: url("../../img/icons/datasets/blijvendgrasland.svg");
        background-size: 80% 80%;
      }

      &.cadastre {
        background-image: url("../../img/icons/datasets/kadaster.svg");
        background-size: 80% 80%;
      }

      &.dem {
        background-image: url("../../img/icons/datasets/hoogte.svg");
        background-size: 80% 80%;
      }

      &.n2k {
        background-image: url("../../img/icons/datasets/n2k.svg");
      }

      &.erosion {
        background-image: url("../../img/icons/datasets/erosion.svg");
        background-size: 75% 75%;
      }
      &.nitrate_area {
        background-image: url("../../img/icons/datasets/nitrate_area_type.svg");
        background-size: 75% 75%;
      }

      &.organic {
        background-image: url("../../img/icons/datasets/organic.svg");
      }
      &.property_i {
        background-image: url("../../img/icons/datasets/overheid.svg");
      }
      &.rsi {
        background-image: url("../../img/icons/datasets/forbidden_potato_irrigation.svg");
      }
      &.rsp {
        background-image: url("../../img/icons/datasets/forbidden_potato.svg");
      }
      &.trench {
        background-image: url("../../img/icons/datasets/trenches.svg");
        background-size: 90%;
      }
      &.ewsP {
        background-image: url("../../img/icons/datasets/ews_p.svg");
        background-size: 70%;
        display: none;
      }
      &.precip_deficit {
        background-image: url("../../img/icons/datasets/dry.svg");
      }
      &.nitrate {
        background-image: url("../../img/icons/datasets/nitrate.svg");
        background-size: 75%;
      }
      &.nva {
        background-image: url("../../img/icons/datasets/nv.svg");
        background-size: 90%;
      }
    }
  }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: map-get($grid-breakpoints, "md")) {
  .mod_bb_datasets {
    position: absolute;
    display: block;
    background-color: rgba($black, 0.6);
    top: $headerHeight-xs;
    .btn-group-wrapper {
      max-width: calc(100vw - 440px);
      width: auto;
    }
    .btn-group {
      display: flex;
    }
  }
}

@media (max-width: map-get($grid-breakpoints, "md")) {
  .mod_bb_datasets.show {
    display: block;
    background-color: rgba($black, 0.6);
    height: auto;
    z-index: 1500;

    .btn-group-wrapper {
      display: flex;
      flex-direction: column;
      position: relative;
      height: 70vh;
      overflow-y: auto;
      overscroll-behavior: none;

      .btn-group {
        display: flex;
        flex-direction: column;
        height: auto;
        overflow-y: visible;
        text-align: left;

        .btn {
          max-width: 50px;
          width: 50px;
          max-height: 50px;
          height: 50px;
          margin: 5px;
          border-radius: 50%;
        }
      }
    }
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: map-get($grid-breakpoints, "lg")) {
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: map-get($grid-breakpoints, "xl")) {
}
