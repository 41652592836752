@import '../../../variables';

.mod_bb_lease {
  .subtitle-icon {
    margin-right: 8px
  }
}

.lease_conditions_popover {
  p {
    text-align: start;
  }

  ul {
    padding-left: 16px;
  }

}