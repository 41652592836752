/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */
@import '../../variables';

body .mod_bb_main_panel {
  box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 100%;
  overflow: visible;
  border: 0;
  top: 100vh; // Fallback for browsers that do not support Custom Properties
  top: calc(var(--vh, 1vh)); // dynamic value
  margin-top: 0;
  display: none;
  background-color: transparent;
  font-size: 14px;
  -webkit-overflow-scrolling: touch;  // ios hack https://apple.stackexchange.com/a/219211

  .blue { // utility class
    color: $blue;
  }

  &.is-open {
    display: block;
    animation: mainPanelSlideUp $animationSpeed;
    animation-fill-mode: forwards;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }

  > article {
    display: none !important;
    position: relative;

    &.active {
      display: block !important;
    }

    &.mod_bb {
      background-color: $white;
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      width: 100%;
      min-height: calc(100vh - 126px);  // $headerHeight-xs + $asideNavHeight-xs;
      z-index: 700;
      -webkit-overflow-scrolling: touch;
      padding-top: 5px;
      padding-left: 0;
      padding-right: 0;
    }

    hr.mobile-hr {
      display: block;
      height: 8px;
      background-color: #cacaca;
      width: calc(100vw / 3);
      border-radius: 4px;
      position: relative;
      z-index: inherit;
      margin: 0 auto;
    }
    &.mod_bb_my_farm{
      hr.mobile-hr{
        top: -18px;
      }
    }

  }
}


/* Small devices (tablets, 768px and up) */
@media (min-width: map-get($grid-breakpoints, "md")) {
  body .mod_bb_main_panel {
    animation: none !important;
    margin-top: 0 !important;
    overflow: hidden;
    background-color: $white;
    top: 0;
    z-index: 700;
    min-height: 100vh;
    width: $mainPanelWidth + $asideNavWidth-sm;
    transform: translateX(-($mainPanelWidth + $asideNavWidth-sm));
    transition: transform $animationSpeed ease-in-out;
    padding-top: $headerHeight-xs;
    padding-left: $asideNavWidth-sm;
    // overscroll-behavior: none;

    &.is-open {
      // transform: translateX(0px);
      transform: none;
    }

    > article {
      left: -$mainPanelWidth;
      transition: right .12s ease-in-out;

      hr.mobile-hr {
        display: none;
      }

      &.active {
        left: 0;
        transition-delay: .2s;
      }

      &.mod_bb {
        padding: 20px;
        min-height: calc(100vh - 60px);
        border-radius: unset;
      }

    }

  }

}

/* Medium devices (desktops, 992px and up) */
@media (min-width: map-get($grid-breakpoints, "lg")) {
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: map-get($grid-breakpoints, "xl")) {
}