/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */
@import '../../variables';

.bunder_tabs_affix {
  height: 70px;
  background: $white;
  .sticky {
    z-index: 2;
  }
}

.mod_bb_tabs {
  width: 100%;
  z-index: 401;
  background-color: lighten($black, 95%);
  max-height: $tabsHeight;

  @media (max-width: map-get($grid-breakpoints, "md")) {
    background-color: $white;
  }

  ul.nav {
    padding: 0;
    margin: 0;
    border-radius: 0;
    & > li {
      float: left;
      display: block;
      margin-left: 0;
      margin-right: 0;
      width: 20%;
      border-right: 1px solid lighten($base, 60%);

      a {
        font-size: 0.786em;
        margin-right: 0;
        display: block;
        padding: 0;
        background-color: transparent;
        height: $tabsHeight;
        text-align: center;
        border-radius: 0;
        color: $base;
        border: 0 !important;
        position: relative;

        &:focus {
          outline: none;
        }

        > i {
          position: absolute;
          top: $gutter / 4;
          left: 50%;
          transform: translateX(-50%);
          display: block;
          width: 40px;
          height: 40px;

          &.icon_facts {
            background: url("../../img/icons/icon_facts.svg") no-repeat center center;
          }
          &.icon_soil {
            background: url("../../img/icons/icon_soil.svg") no-repeat center center;
          }
          &.icon_growth {
            background: url("../../img/icons/icon_growth.svg") no-repeat center center;
          }
          &.icon_height {
            background: url("../../img/icons/icon_height.svg") no-repeat center center;
          }
          &.icon_property {
            background: url("../../img/icons/icon_cadastre.svg") no-repeat center center;
            background-size: 80% 80%;
          }
          &.icon_showmore {
            background: url("../../img/icons/icon_showmore.svg") no-repeat center center;
          }
          &.icon_nature {
            background: url("../../img/icons/icon_nature.svg") no-repeat center center;
          }
          &.icon_water {
            background: url("../../img/icons/icon_trenches.svg") no-repeat center center;
          }
        }

        > span {
          position: absolute;
          display: block;
          width: 100%;
          height: auto;
          bottom: $gutter / 4;
        }
      }

      > ul.dropdown-menu {
        left: auto;
        right: 0;
        min-width: 200px;
        margin: 0;
        padding: 0;
        font-size: inherit;
        border-radius: 0;
        border: 0;

        li {
          display: block;
          float: none;
          width: 100%;
          border-bottom: 1px solid lighten($base, 60%);

          &:last-child {
            border-bottom: 0;
          }

          a {
            text-align: left;
            i {
              top: 50%;
              transform: none;
              left: $gutter / 4;
              transform: translateY(-50%);
            }
            span {
              bottom: auto;
              top: 50%;
              transform: translateY(-50%);
              padding-left: $gutter / 2 + 40px;
            }
          }

          &.active {

            > a {
              background-color: $white;
              color: $blue;

              i {
                &.icon_facts {
                  background: url("../../img/icons/icon_facts_hover.svg") no-repeat center center;
                }
                &.icon_soil {
                  background: url("../../img/icons/icon_soil_hover.svg") no-repeat center center;
                }
                &.icon_growth {
                  background: url("../../img/icons/icon_growth_hover.svg") no-repeat center center;
                }
                &.icon_height {
                  background: url("../../img/icons/icon_height_hover.svg") no-repeat center center;
                }
                &.icon_nature {
                  background: url("../../img/icons/icon_nature_hover.svg") no-repeat center center;
                }
                &.icon_water {
                  background: url("../../img/icons/icon_trenches_hover.svg") no-repeat center center;
                }
              }
            }
          }

        }
      }
      &:last-child {
        border-right: 0;
      }
    }
  }
  ul.bar_6.nav > li {
    width: 16.6%;
  }
}

.bunder_tabs_affix .sticky {
  z-index: 801;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: map-get($grid-breakpoints, "md")) {
  .mod_bb_tabs {
    ul.nav {
      li {
        &.active {
          > a {
            background-color: $white;
            // background-color: lighten($black, 95%);
            color: $blue;
            i {
              &.icon_facts {
                background: url("../../img/icons/icon_facts_hover.svg") no-repeat center center;
              }
              &.icon_soil {
                background: url("../../img/icons/icon_soil_hover.svg") no-repeat center center;
              }
              &.icon_growth {
                background: url("../../img/icons/icon_growth_hover.svg") no-repeat center center;
              }
              &.icon_height {
                background: url("../../img/icons/icon_height_hover.svg") no-repeat center center;
              }
              &.icon_nature {
                background: url("../../img/icons/icon_nature_hover.svg") no-repeat center center;
              }
              &.icon_water {
                background: url("../../img/icons/icon_trenches_hover.svg") no-repeat center center;
              }
            }
          }

          &.dropdown {
            > a {
              cursor: pointer;
              background-color: transparent;
              > span {
                color: $base;
              }
            }
          }
        }
      }
    }
  }

}

/* Medium devices (desktops, 992px and up) */
@media (min-width: map-get($grid-breakpoints, "lg")) {
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: map-get($grid-breakpoints, "xl")) {
}