@import '../../variables.scss';

@media (min-width: map-get($grid-breakpoints, "md")) {
  .mod_bb_panel_header {
    .explanation {
      max-width: $mainPanelWidth - (40px + ($gutter*3));
    }
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: map-get($grid-breakpoints, "lg")) {
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: map-get($grid-breakpoints, "xl")) {
}