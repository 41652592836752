@import '../../../variables.scss';

  div .thumbnails {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: .25rem!important;
    width: 100%;
    position: relative;
    @media (min-width: map-get($grid-breakpoints, "md")) {
      position: fixed;
      left: $mainPanelWidth + $asideNavWidth-sm +15px;
      bottom: 10px;
      transition: all 0.2s ease;
      width: calc(100vw - #{$mainPanelWidth} - #{$asideNavWidth-sm}  - 80px);
    }

    .card-container {
      flex-wrap: nowrap !important;
      flex-direction: row !important;
      display: flex;
      overflow-x: auto;
      scroll-behavior: smooth;

      @media (max-width: map-get($grid-breakpoints, "md")) {
        padding-inline-end: 60px;
        padding-inline-start: 24px;
        align-items: center;
        position: relative;
        .btn{
          border-radius: 50%;
          width: 40px;
          height: 40px;
          padding: 0;
          color: $white;
          background-color: $color-primary-50;
          position: absolute;
          z-index: 20;
          left: 0px;
          border: none;
          box-shadow: $shadow-30;
        }
      }
      .card {
        padding: 5px;
        height: 110px;
        display: inline-table;
        @media (max-width: map-get($grid-breakpoints, "md")) {
          height: 280px;
        }
        .image-year {
          font-weight: bold;
          height: 18px;
        }
        .image-card {
          background-color: white;
          border: 1px solid rgba(0,0,0,.1);
          border-radius: 4px;
          width: 80px;
          height: 80px;
          text-align: center;
          padding: 5px;
          cursor: pointer;
          @media (max-width: map-get($grid-breakpoints, "md")) {
            height: 250px;
            width: 250px;
          }
          img {
            height: 50px;
            width: 100%;
            object-fit: contain;
            @media (max-width: map-get($grid-breakpoints, "md")) {
              height: 220px;
            }
          }
        }
      }
    }
  }