@import './../../../variables.scss';

$block: 'c-tag';
.#{$block} {
  border-radius: $border-radius-10;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Poppins';
  background: #F1F5F4;
  padding: 2px 3px;
  margin-right: 4px;
}