.bb_lease {
  .btn.lease {
    background-image: url("../../../img/icons/datasets/plots.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 8px;
    border-width: 3px;
    margin-right: 2px;
  }
  img.icon {
    width: 48px;
    height: 48px;
    display: inline-block;
  }
  ul.links {
    list-style: none;
  }
  img.avatar{
    border-radius: 50%;
    height: 64px;
    width: 64px;
  }
  //h2 { BinB wens. is lelijk
  //  color: #0fb2aa;
  //}
}