@import '../../variables';

.settings-icon {
  background-color: transparent;
  color: $blue;
  line-height: 12px;
}

.mod_bb_settings {
  background-color: $white;
  padding: $gutter;

  .MuiAccordionSummary-content.Mui-expanded {
    margin:0;
  }

  .feature {
    .feature-header {
      display: flex;
      align-items: center;

      h3 {
        flex-grow: 1;
        margin-left: 15px;
        font-size: 20px;
        margin-top: 0.4em;
      }
    }

    .feature-context {
      p {
        margin-bottom: 30px;
      }

      .short_description {
        font-style: italic;
      }

      .context-with-logo {
        display: flex;
        .logo {
          margin-right: 10px;
          border-radius: 10px;
          height: 80px;
        }
      }

      .context-with-image {
        margin-top: 5px;

        .example {
          margin-top: 5px;
          float:right;
          margin-left: 15px;
          border-radius: 10px;
          height: 150px;
          width: 180px;
          box-shadow: $box-shadow;
        }
      }
    }

    .feature-example {
      display: flex;
      justify-content: center;
      flex-direction: column;

      h4 {
        margin-top: 5px;
        text-align: center;
      }
    }

    .panel {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0;
      border-radius: 10px;
      background-color: $cream;
      box-shadow: $box-shadow;
      transition: all .1s ease-out;

      .btn {
        background-color: $blue;
        color: $white;
        transition: inherit;

        &.disabled {
          background: $gray;
          transition: inherit;
        }
      }
    }
  }

  .feedback-placeholder {
    padding-top: 30px;
  }
  .MuiPaper-elevation1 {
    box-shadow: none;
    border-bottom: 1px solid #139CC2;

    .MuiAccordionDetails-root {
      display: block;
    }
  }

}