/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */
@import '../../variables';

.mod_bb_map {
  position: fixed;
  width: 100%;
  height: 100%;
  min-height: 50px;
  overflow: hidden;
  top: 0;
  left: 0;
  #advertisement {
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: transparent;
  }
  &.pro_map{
    .map{
      height: 100%;
    }
  }
  .map {
    width: 100%;
    height: calc(100% - 96px);
  }
  .inner {
    background-color: $lime;
    position: absolute;
    top: $headerHeight-xs + $asideNavHeight-xs;
    right: 0;
    width: 100%;
    height: 100%;
    > span {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .leaflet-google-mutant {
    visibility: hidden;
  }
  .leaflet-top {
    top: $headerHeight-xs + 65px + 45px; //$navbar-height + 65px
  }
  .leaflet-control-layers-overlays, .leaflet-control-layers-separator {
    display:none;
  }
  .leaflet-draw-tooltip-subtext {
    color: #ffffff;
  }
  .leaflet-draw-guide-dash {
    opacity: 1;
  }
  .leaflet-bar a, .leaflet-bar a:hover {
	width: 30px;
	height: 30px;
	line-height: 30px;
  }
  .leaflet-control-zoom-in, .leaflet-control-zoom-out {
    font-size: 22px;
  }
  .leaflet-touch { // mobiel fix border rondom layer-select
    .leaflet-bar, .leaflet-control-layers {
      border: none;
    }
  }
  .low-bar {
    position: fixed;
    bottom: 16px;
    z-index: 401;
    text-align: center;
    width: 100%;
  }
}

/* Don't show on too small devices */
@media (max-height: 350px) {
  .mod_bb_map {
    .leaflet-bar a.leaflet-control-draw-measure {
      display: none
    }
  }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: map-get($grid-breakpoints, "md")) {
  .mod_bb_map {
    #advertisement{
      position: absolute;
      bottom: 10px;
      left: 105px;
      z-index: 1000;
      width: 390px;
      height: 260px;
    }
    .map{
      height: 100%;
    }
    .leaflet-top {
      top: 100px;
    }
    .inner {
      top: $headerHeight-xs;
    }
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: map-get($grid-breakpoints, "lg")) {
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: map-get($grid-breakpoints, "xl")) {
}