@import "../variables";
.modal {
  .modal-dialog {
    margin-top: $headerHeight-xs + $gutter;

    .modal-content {
      border-radius: 0;
      border: 0;
      
      .modal-header {
        border: 0;
        button.close {
          opacity: 1;

          // included from btn.close
          width: 30px;
          height: 30px;
          display: block;
          margin: 1px;
          background-color: transparent;
          background: url("../../img/icons/icon_close.svg") no-repeat center center;
          background-size: 30px;
          text-indent: -999999px;
        }
      }

      .modal-footer {
        border: 0;
      }
    }
  }
}