@import "./../../variables.scss";


.c-detail-row{
    padding: 4px 0;
    // box-shadow:0px 1px 0px $color-neutral-20, inset 0px 1px 0px $color-neutral-20;

    &--flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
    }

    .flag-container {
        flex: auto;
        display: flex;
        flex-flow: row-reverse;
        align-items: center;
        flex-wrap: wrap;

        .flag-icon {
            box-shadow: 0 0 0 1px $color-neutral-20;
            transform: scale(0.8, 0.8);
            //max-height: 48px;
            max-width: 48px;
        }

    }
}