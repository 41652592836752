@import '../../variables';

.mod-bb-scroll-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-y: hidden;
  width: 100%;
  height: calc(100vh - 135px); // header:56px - tabs:70px - title:39px - padding:20px - extra-padding:20px

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}

.bb_pages_contact {
  .form-control {
    color: #505052;
  }
}

.welcome-logo {
  margin-left: 12px;
  float:right;
  width: 100px;
  height: 100px;
}

.section_page {
  .container_page {
    padding: 0;
    overflow: hidden;
    &.scrollable {
      overflow-y: auto;
    }
    height: auto;
    position: fixed;
    left: 0;
    top: $headerHeight-xs; // + $tabsHeight
    right: 0;
    bottom: 0;
    margin: 0;
    background: white;
    z-index: 850;
    width: auto !important;
    transition: height .2s ease-in-out;
    .news_title {
      padding: 10px 0 10px 10px;
      border-bottom: 1px solid rgba(0,0,0,0.1);
      .page-title {
        display: inline-block;
      }
    }
    .row {
      margin:0;
    }
  }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: map-get($grid-breakpoints, "md")) {
  .section_page {
    .container_page {
      left: 95px;
      top: $headerHeight-xs;
    }
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: map-get($grid-breakpoints, "lg")) {
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: map-get($grid-breakpoints, "xl")) {
  .section_page {
    div.container_page {
      margin: 0 auto;
    }
  }
}