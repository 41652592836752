.video-wide-screen{
  width: 100%;
  padding-top: 56.25%;
  height: 0px;
  position: relative;
  > video{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-Fit: cover;
  }
}