@import '../../../variables';

@media (min-width: map-get($grid-breakpoints, "md")) {
  .legend-container {
    position: fixed;
    bottom: 170px;
    right: 10px;
    padding: 0;
    box-sizing: border-box;
    .legend-item {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
      background-color: rgba(30, 50, 45, 0.9);
      color: #fff;
      padding: 12px 16px;
      margin: 8px 0 8px 8px;
      border-radius: 4px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      position: relative;
      width: 240px;
      box-sizing: border-box;
      display: inline-block !important;
      .legend-item-list {
        list-style-type: none;
        margin: 0;
        .legend-item-list-item {
          font-size: 0.8em;
          color: rgba(255, 255, 255, 0.87);
          .legend-item-specs {
            width: 40%;
            float: left;
            .legend-item-spec-color {
              width: 12px;
              height: 12px;
              border-radius: 12px;
              display: block;
              float: left;
              margin-top: 2px;
              overflow: hidden;
            }
          }
          .legend-item-histo {
            float: left;
            width: 60%;
            .legend-item-histo-count {
              float: right;
              padding-right: 8px;
              text-align: right;
              box-sizing: border-box;
            }
            .legend-item-histo-bar-wrapper {
              float: right;
              width: 70px;
              .legend-item-histo-bar {
                display: block;
                height: 6px;
                border-radius: 10px;
                background-color: #fff;
                position: relative;
                top: 6px;
              }
            }
          }
        }
      }

      header {
        .legend-item-description {
          font-size: 0.9em;
          //display: block;
          //width: 150px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .legend-item-date {
          font-weight: normal;
          font-size: 0.8em;
          opacity: 0.87;
          display: block;
          //width: 50%;
        }
      }
      &.last {
        margin-bottom: 0;
      }
    }
    p {
      color: rgba(255, 255, 255, 0.4);
    }
    hr {
      margin: 8px 0;
      border-color: rgba(255, 255, 255, 0.4);
    }
    header {
      background-image: none;
      border: 0;
      color: #fff;
      border-radius: 0;
      background-color: transparent;
      margin: 0;
      i {
        color: #fff;
      }
      i.icon-ok {
        color: black;
      }
    }
  }


}
