i.social {padding-right: 10px}
.map_100p .leaflet-container {
  height: 100%; width: 100%;
}

.media {
  &--hover {
    background-color: #dae0e5;
  }
}
