@import '../../../variables';

.mod_bb_farm_panel_header {
  height: 240px;
  position: relative;
  margin-top: -33px;

  .c-hero {
    background-size: 100% 100%; 
  }

  .bunder_hero_image {
    width: 100vw;
    height: 240px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }

  .farm-pannel-btns {
    position: absolute;
    top: 10px;
    width: 100%;
    button.btn-close {
      display: inline-block;
      z-index: 100;
      float: right;
    }
  }

  .inner {
    display: flex;
    flex: auto;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 8px;

    .avatar {
      border-radius: 50%;
      height: 64px;
      width: 64px;
    }

    .farmer_info {
      position: relative;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      img.avatar {
        width: $avatarSize;
        height: $avatarSize;
        border-radius: $avatarSize;
        display: inline-block;
        position: absolute;
        left: 0;
        bottom: -$gutter/3;
      }
      h2.farm_title {
        font-size: 1.286em;
        padding-right: 25px + $gutter;
        margin: 0;
        color: $white;
      }
      img.avatar + h2.farm_title {
        padding-left: $avatarSize + $gutter/2;
      }

    }
  }

  &:after, &:before {
    display: none;
  }

}

@media (min-width: map-get($grid-breakpoints, "md")) {
  .mod_bb_farm_panel_header {
      height: 280px;
      margin: -20px -20px 10px -20px;
      overflow: hidden;

      .bunder_hero_image {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
      }

      .farm-pannel-btns {
        z-index: 100;
        button.btn-close {
          display: inline-block;
          float: right;
          margin: 5px 10px;
        }
      }

      .inner {
        .farmer_info {
          position: absolute;
          padding-left: $gutter / 2;
          padding-right: $gutter / 2;
          color: $white;
          transform: none;
          top: auto;
          bottom: $gutter;
          z-index: 100;
          h2.farm_title {
            margin: 20px 0 10px 0;
          }

          img.avatar {
            left: $gutter / 2;
            bottom: 0;
          }
        }
      }
    }

}

/* Medium devices (desktops, 992px and up) */
@media (min-width: map-get($grid-breakpoints, "lg")) {
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: map-get($grid-breakpoints, "xl")) {
}