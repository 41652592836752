/* page cases */
.case-card {
  position: relative;
  height: 240px;
  overflow: hidden;
  border: 0;
  cursor: pointer;

  &::before, &::after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 2;
    content: "";
    transition: all 0.2s ease;
  }

  &::before {
    background: black;
    opacity: 0.1;
  }

  &::after {
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.75) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.75) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.75) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#66000000', endColorstr='#e6000000', GradientType=0);
    /* IE6-9 */
  }

  div {
    position: absolute;
    bottom: -76px;
    padding: 32px;
    z-index: 3;
    transition: all 0.2s ease;

    h2 {
      color: #fff;
      font-size: 11px;
      font-weight: bold;
      text-transform: uppercase;
      position: relative;
      padding-bottom: 24px;
      margin-bottom: 24px;
    }

    &::after {
      display: block;
      height: 2px;
      position: absolute;
      left: 0;
      bottom: 0;
      content: "";
      background-color: #ffc341;
      width: 48px;
    }

    p {
      color: #fff;
    }

    em {
      font-weight: bold;
      font-size: 11px;
      color: #fff;
      text-transform: uppercase;
      display: block;
      font-style: normal;
      margin-bottom: 4px;
    }

    span {
      font-size: 11px;
      color: #fff;
      opacity: 0.6;
      font-style: italic;
      display: block;
      margin-bottom: 24px;
    }

    .btn {
      clear: both;
    }

    figure {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      margin: 0;

      img {
        border-radius: 0;
        transition: all 0.2s ease;
        min-height: 100%;
      }
    }

  }

  &:hover {
    &::before {
      opacity: 0;
    }

    div {
      bottom: 0;
    }

    figure img {
      transform: scale(1.05);
    }
  }
}