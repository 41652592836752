 @import '../../variables';
.mob_bb_share {
  .btn-group {
    .btn-group {
      padding: 5px;

      .btn {
        border: 2px solid $blue;
        color: $blue;
        font-size: 12px;

        &:hover {
          background-color: $blue;
          color: $white;

          &.grey {
            .bb_pro_feature {
              color: #ffc341;
              background-color: #233a32;
            }
          }
        }

        &.btn-share {
          text-align: center;
          padding-left: 30px;
          background: url("../../img/icons/icon_share_blue.svg") no-repeat left calc(50% - 23px) center $white;
          background-size: 21px 23px;
          color: $blue;

          &:hover {
            background: url("../../img/icons/icon_share_white.svg") no-repeat left calc(50% - 23px) center $blue;
            background-size: 21px 23px;
            color: $white;
          }
        }

        &.grey {
          border-color: grey;
          color: grey;

          .bb_pro_feature {
            color: white;
            background-color: #aaa;
          }
        }

        &.btn-delete-list {
          height: 36px;
          border-color: #a94442;
          color: #a94442;
          filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
          background: url("../../img/icons/icon_trash_alt_solid.svg") no-repeat center center;
          background-size: 17px 23px;
        }
      }
    }
  }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: map-get($grid-breakpoints, "md")) {
  .mob_bb_share {
    position: fixed;
    padding-top: 0;
    padding-bottom: 0;
    bottom: 0;
    width: $mainPanelWidth;
    z-index: 400;
    left: $asideNavWidth-sm;
  }
}