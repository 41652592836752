/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */
@import '../../variables';

.mod_bb_header.navbar {
  .dropdown-item.active, .dropdown-item:active {
    background: #ddd;
  }
  .container{
    max-width: 100% !important;
  }
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $headerHeight-xs;
  background-color: $dacomyellow;
  z-index: 900;
  box-shadow: 0 0 7.5px 0 rgba($black, 0.2);
  border: 0;
  border-radius: 0;
  margin-bottom: 0;
  padding: 0;
  .container{
    padding: 0;
  }
  .navbar-toggler{
    margin-right: 1rem;
    border-color: #444;
  }
  .navbar-collapse{
    ul.navbar-nav{
      a.nav-link{
        padding: 8px 15px;
      }
    }
  }

  .navbar-toggle {
    border-color: #444;
    position: fixed;
    top: 0px;
    right: 0px;
    &:hover, &:focus {
      background-color: var(--bb-background);
    }
    .icon-bar {
      background-color: #444;
    }
  }
  .navbar-nav > li > a {
    color: #233a32;
  }
  .navbar-nav > .active, .navbar-nav > li {
    > a, > a:hover, > a:focus {
      background-color: transparent;
    }
  }

  button.btn-mainnav i span {
    background-color: $dacomgreen;
  }
  .dropdown-menu-left{
    .dropdown-menu{
      left: auto;
      right: 0;
    }
  }
  .navbar-brand, .navbar-brand-nav, ul.navbar-nav {
    .cropfields-dropdown {
      display: block;
      padding: 15px 0 15px 10px;
      height: $headerHeight-xs;
      margin-bottom: 0;
      &.nav-item {
        padding: 6px 0;
      }
      &.show{
        background-color: var(--bb-background);
      }

      .dropdown-menu {
        border: 1px solid #e5e5e5;
        .dropdown-item {
          padding: .25rem 1.5rem;
          a {
            padding: 0;
          }
        }
      }
    }
  }

  .navbar-brand {
    height: $headerHeight-xs;;
    padding: 0;
    .nav-link{
      color: $dacomgreen;
    }
  }

  .dropdown-menu {
    border-radius: 0;
    width: max-content;
    .dropdown-item{
      cursor: pointer;
    }
  }
  .bb_country_select {
    .dropdown-menu {
      width: auto;

      .dropdown-item {
        padding-left: 0.5rem;
        a.nav-link {
          line-height: 20px;
          color: #444;
          padding: 0;
        }
      }
    }
  }
  .collapse.show, .collapsing {
    background: white;
    overflow-y: auto;
    width: 100vw;
    margin: 0px;
  }

   // Styles for ReportHeader, maybe move to own scss file?
  .back-btn {
    padding: 6px 0 0 10px;
    display: inline-block;
  }
  .share-container {
    padding: 8px 20px 0 0;
  }
  button.btn-share {
    font-size: 15px;
  }
}

@media (min-width: 768px) {
  .mod_bb_header.navbar {
    .navbar-collapse{
      ul.navbar-nav{
        a.nav-link{
          padding: 0;
        }
      }
    }

    .container {      
      .navigation-list{
          align-items: center;
          list-style: none;
          margin: 0;   
      }

      .container {
        padding: 0 .5rem;
      }

      .navbar-brand {
        margin-left: 0px;
      }
      li.dropdown-item.active{
        padding-left: 0.4rem;
      }
      ul.navbar-brand-nav.navbar-nav {
        > li > a {
          padding: 15px 0;
        }
      }

      .navbar-nav {
        height: $headerHeight-xs;
        >li > a.nav-link{
          padding: 18px 1rem;
        }
        > li.avatar > a.nav-link {
          padding: 8px 15px;
          img {
            border-radius: 40px;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1)
          }
        }

        > .active {
          > a, > a:hover, > a:focus {
            background-color: inherit;
          }
        }

        > .open, .show {
          > a, > a:hover, > a:focus {
            background-color: var(--bb-background);
            color: #555;
          }
        }

        .bb_country_select, .bb_farm_switch {
          a {
            height: 100%;
          }
        }

        .bb_country_select .dropdown-menu {
          position: absolute;
          width: max-content;
        }

        .bb_farm {
          padding: 18px 10px;
        }

        .bb_farm_switch {
          background-color: inherit;
          color: #333;
        }

        .avatar {
          .dropdown-item {
            //padding: 5px;
            a {
              color: #333;
            }
          }
        }
      }

      .navbar-brand, .navbar-brand-nav {
        .country {
          display: none
        }
      }

      .collapse.show, .collapsing {
        background: inherit;
      }
    }
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: map-get($grid-breakpoints, "lg")) {
  .mod_bb_header.navbar {
    padding: 0 32px;
    &.navbar {
      .container .navbar-brand {
        margin-left: -15px;
      }
    }
  }
}
