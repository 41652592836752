/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */
@import '../../variables';

/* See https://github.com/searchkit/searchkit/issues/618 , searchkit is broken */
*, *:before, *:after {
  box-sizing: border-box !important;
}


.mod_bb_facet {
  padding-bottom: 0;
  //font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  padding-right: 10px;
  padding-left: 10px;
  background:white;

  .recharts-surface {
    overflow: visible;
  }

  .recharts-pie-sector { cursor: pointer }

  .titlebox {
    float: left;
    .center {
      float: none;
      text-align: center;
      padding-top: 30%;
    }
  }

  // TODO: merge this collapsable / closable styles!
  .sk-panel {
    .sk-panel__header {
      &.is-collapsable:after {
        display: block;
        width: 15px;
        height: 10px;
        position: absolute;
        bottom: 4px;
        right: 0;
        content: "";
        background: url("../../img/icons/icon_facetcollapse.svg") no-repeat center center transparent;
        transform: rotate(0);
        transition: transform $animationSpeed ease-in-out;
        top: auto;
        margin-top: auto;
        left: auto;
        border: none;
      }
      &.is-collapsed:after {
        transform: rotate(180deg);
      }
    }
    .sk-panel__content.collapsable {
      overflow: hidden;
      max-height: 0;
      -webkit-transition: max-height 0.5s;
      -moz-transition: max-height 0.5s;
      transition: max-height 0.5s;
      &.is-collapsed {
        display: block;
        max-height: 2000px;
      }
    }
  }

  .closable {
    .toggle {
      cursor: pointer;
    }
    .toggle:after {
      display: block;
      width: 15px;
      height: 10px;
      position: absolute;
      bottom: 4px;
      right: 0;
      content: "";
      background: url("../../img/icons/icon_facetcollapse.svg") no-repeat center center transparent;
      transform: rotate(180deg);
      transition: transform $animationSpeed ease-in-out;
    }
    .content {
      overflow:hidden;
      max-height: 0;
      -webkit-transition: max-height 0.5s;
      -moz-transition: max-height 0.5s;
      transition: max-height 0.5s;
    }
    &.open {
      .content {
     	max-height: 2000px;
      }
      .toggle:after {
        transform: rotate(0);
      }
    }
    .more {
      padding-left: 10px;
      cursor: pointer;
    }
  }

  .sk-hits-stats {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    margin-bottom: 10px;
  }

  .sk-mtoggle {
    font-family: "Courier New", monospace;
  }

  .sk-layout__results {
    top: 100px;
    padding: 0 0 250px 0;
    margin: 0;
    position:  fixed;
    left: 525px;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    .sk-action-bar {
      margin: 16px;
      .sk-action-bar-row {
        margin: 0;
      }
    }
  }

  .sk-item-list-option__count, .sk-hierarchical-menu-option__count {
    color: #999;
  }

  .sk-hierarchical-menu-list, .sk-panel {
    margin-bottom: 12px;
  }

  .sk-hierarchical-menu-list__header, .sk-panel__header {
    color: #139CC2;
    display: block;
    border-bottom: 1px solid #139CC2;
    text-align: left;
    padding: 0 !important;
    border-radius: 0;
    position: relative;
  }

  .sk-hierarchical-menu-list__header.free {
    color: grey;
    border-bottom-color: grey;
  }
  .bb_pro_feature {
    font-size: 9px;
    padding-bottom: 1px;
    padding-top: 1px;
  }
  .sk-action-bar-row {
    position: fixed;
    left: 525px;
    top: 56px;
  }
  .sk-selected-filters-option {
    background: white;
    padding: 5px;
  }

  .sk-selected-filters__item {
    margin: 5px;
  }
  .sk-selected-filters-option__remove-action {
    padding-right: 5px;
  }
  .sk-select {
    max-height: 31px;
    select {
      height: 31px;
      padding-right: 25px;
    }
  }
  .btn-close {
    margin: 0;
    height: 35px;
  }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: map-get($grid-breakpoints, "md")) {
  .mod_bb_facet {
    padding: $gutter;

    footer {
      display: none;
    }

  }

}

/* Medium devices (desktops, 992px and up) */
@media (min-width: map-get($grid-breakpoints, "lg")) {
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: map-get($grid-breakpoints, "xl")) {
}

.mod_bb_facet_modal {
  .bb-nr-specification {
    font-weight: bold;
    margin-bottom: 10px;
    > tbody > tr > td {
      border-top: none;
    }
  }
  .attention {
    font-weight: bold;
    color: red;
  }
}
