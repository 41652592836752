/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */
@import '../../variables.scss';

div.crop-rotation {
  button.btn.btn-tertiary{
    font-weight: bold;
    font-size: 14px;
    color: $black; 
    font-family: $font-family-body;

    >i {
      font-size: 14px;
      font-weight: bold;
    }
    
  }
}

#crop-history-table {
  direction: ltr;
  width: auto;
  max-width: none;
  th, td {
    text-align: center;
    border:none;
    padding: 0 0 0 0.6rem;
  }
  .icon-placeholder {
    position: relative;
    height: 50px;
    //width: 50px;
    display: inline-block;
    float:none;
    margin:0;
    > i {
      line-height: 1.2em;
      font-size: 40px;
    }
  }
  .background-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index:0;
  }
}

#soil-triangle {
  text-align: center;
}

.popover-content, .tooltip {
  .color-box {
    width: 8px;
    height: 8px;
    display: inline-block;
    background-color: #FFFFFF;
    margin-right: 5px;
    float: left;
  }
}


.flag-icon { // if css fails show no
  object-fit: none;
  object-position: 64px 64px;
  max-height: 64px;
  max-width: 64px;
}

.flag-iconfull {
  max-height: none;
  max-width: none;
}

.mod_bb_report {
  .bb_bunder_container{
    padding: 20px;
    background-color: #fff;
  }
  .right { float:right;}
  .history-container {
    @media (hover: none) { // smartphone get rid of ugly bar
      &::-webkit-scrollbar { /* see https://stackoverflow.com/a/43186214/193886 */
        width: 0;
        background: transparent; /* make scrollbar transparent */
      }
    }
    overflow-y: auto;
    direction: rtl;
  }

  .color-box {
    width: 15px;
    height: 15px;
    display: inline-block;
    background-color: #FFFFFF;
    margin-right: 5px;
    float:left;
  }

  section {
    padding: $gutter / 2;
    background-color: $white;
    position: relative;
    border-bottom: 1px solid lighten($base, 60%);

    .title {
      display: flex;
      color: $blue;
      // align-items: center;
      h2 {
        flex-grow: 1;
      }
      span {
        margin-right: 8px;
      }
    }

    h2 {
      color: $black;
      font-size: $font-size-s;
      font-family: $font-family-heading;
      margin-top: 0;
    }

    h3 {
      color: $black;
      font-size: $font-size-xs;
      font-family: $font-family-heading;
      font-weight: 600;
    }

    img.media-object {
      display: block;
      width: 100%;
    }

    &.sized {
      display: grid;
    }
    &.large_sized {
        min-height: 420px;
    }
    &#section_pictures {
      padding: 0;
      padding-bottom: $gutter;

      h2 {
        padding: $gutter;
      }
    }
  }

  .sections {
    &.active {
      padding-top: $tabsHeight
    }
  }

  .bb_selectionButtons_2 {
    padding-left: 30px;
  }
  .bb_selectionButtons_3 {
    padding-left: 20px;
  }
  .bb_selectionButtons_0 {
    padding-left: 0;
  }

  div.back-btn {
    padding: 5px 0 0 $gutter/2;
    .btn-close {
      background-color: $white;
      margin-right:0;
      border-radius: 25px
    }
  }

  .field-attributes {
    .attribute-img {
      background-size: 42px 42px;
      width: 42px;
      height: 42px;
      margin-right:10px;
      float:left;
      // align right-bottom with following settings:
      line-height: 70px;
      text-align: right;
      img {
        display: block;
      }
    }
    .flag-iconfieldprop {
      transform: scale(0.875); // only valid for WTB images 48px -> 42px
      margin-top: -4px;        // only valid for WTB images 48px -> 42px
      width: 48px;             // only valid for WTB images 48px -> 42px
      filter: grayscale(100%);
      &:hover {
        filter: none;
      }
    }
    .gwl-img {
      background: url("../../img/icons/icon_gwl_small.png") no-repeat 25% center;
      background-size: 40%;
    }
    .drone-forbidden {
      background-image: url("../../img/drone-access-forbidden.png");
    }
    .drone-restricted {
      background-image: url("../../img/drone-access-restricted.png");
    }
    .gwp-img {
      background-image: url("../../img/icon_gwp.png");
    }
    .grs-img {
      background-image: url("../../img/icon_permanent_grass.svg");
    }
    .soil-pp {
      background-image: url("../../img/bodempaspoort_bw.png");
    }
    .potato-forbidden {
      background-image: url("../../img/forbidden_potato.svg");
    }
    .irrigation-potato-forbidden {
      background-image: url("../../img/forbidden_potato_irrigation.svg");
    }
    .n2k-img {
      background-image: url("../../img/icon_n2k.svg");
    }
    .dfz-img {
      background-image: url("../../img/icon_dfz.svg");
    }
    .nitrate-img {
      background-image: url("../../img/icon_nitrate.svg");
    }
    .nitrate-focus-img {
      background-image: url("../../img/icon_nitrate_focus.svg");
    }
    .icon-nitrate-area-type-img {
      background-image: url("../../img/icon_nitrate_area_type.svg");
    }
    .erosion-img {
      background-image: url("../../img/icon_erosion.svg");
    }
    .organic-img {
      background-image: url("../../img/icon_organic.svg");
    }
    .potato-virus-img {
      background-image: url("../../img/icon_virus.svg");
    }
    .state-img {
      background-image: url("../../img/icon_nl_state.svg");
    }
    .nh4-img {
      background-image: url("../../img/icon_nh4.svg");
    }
  }

  .state_fetching {
    .bb_selectionButtons, .bb_toggleButton, .bb_download {
      display: none;
    }
  }
  .bb_download { display: none;}

  #section_soil {
    th { text-align: center;}
  }

  #section_advertisement {
    width: 390px;
    height: 260px;
  }

  #section_growth {
    .recharts-wrapper {
      margin-left: -25px
    }
    .bb_toggleButton {
      padding-left: 10px;
    }
    .bb_subscriptionFeature {
      cursor: pointer;
      float:right;
      line-height: 30px;
      padding-right: 20px;
    }
    .keymap p {
      padding-left: 100px;
    }
    .growth-tooltip {
      background-color: rgba(255,255,255,.9); border: 1px solid rgb(204, 204, 204); white-space: nowrap;
      .growth-tooltip-title { padding: 4px 0; background: grey; color: white; text-align: center;}
      td { padding: 3px;}
    }
  }
  #section_height {
    .recharts-wrapper {
      margin-left: -25px
    }
    .bb_toggleButton {
      padding-left: 5px;
      .btn {
        padding: 5px 7.5px;
      }
    }
    p.center {
      text-align: center;
      margin-bottom: 0;
    }
  }
  #section_ews {
    .ews-tooltip {
      background-color: rgba(255,255,255,.9); border: 1px solid rgb(204, 204, 204); white-space: nowrap;
      .ews-tooltip-title { padding: 4px 0; background: grey; color: white; text-align: center;}
      td { padding: 3px;}
    }
  }
  #section_weather {
    .weather-tooltip {
      background-color: rgba(255,255,255,.9); border: 1px solid rgb(204, 204, 204); white-space: nowrap;
      .weather-tooltip-title { padding: 4px 0; background: grey; color: white; text-align: center;}
      td { padding: 3px;}
    }
  }


  .bunder_hero {
    height: max-content;
    position: relative;
    .bunder_hero_image {
      display: none;
    }
    .flag-icon {
      display: inline-block;
      margin-top: 8px;
      &.flag-icon2 {
        margin-left: 10px;
      }
    }
    .upper {
      height: 50px;
    }
    .inner {
      height: max-content;
      background-color: $white;
      padding: 0 $gutter / 2;
      button.btn-favorite {
        position: absolute;
        bottom: 10px;
        right: $gutter;
        z-index: 100;
        &.active {
          background: url("../../img/icons/icon_favorite_hover.svg") no-repeat center center;
        }
      }
      dt, dd {
        width:35%;
        padding: 0;
        margin: 0;
      }
      dd {
        width:65%;
      }
      a.navigate {
        z-index: 100;
        position: absolute; right: $gutter;
        bottom: 50px;
        width: 25px; height: 25px;
        font-size:24px;
        display: inline-block;
      }
      .notsmall { display: none; }
      dl {
        position: relative;
        top: 0px;
        left: 0;
        margin: 10px 0px;
        font-size: 15px;
      }
    }
  }

}

/* Small devices (tablets, 768px and up) */
@media (min-width: map-get($grid-breakpoints, "md")) {
  .popover { max-width: 400px;}
  .mod_bb_report {
     &.mod_bb {
        padding: 0px !important;
     }
    section {
      padding: $gutter;
      background-color: lighten($black, 95%);
      &.active {
        background-color: $white;
      }
    }

    #section_last {
      min-height: calc(100vh - 166px); // 56+70+40
    }

    .bunder_hero {
      height: $bunderHero-sm;
      background-color: $white;
      overflow: hidden;
      .bunder_hero_image {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }

      .inner {
        background-color: transparent;
        position: relative;
        height: 235px;
        .notsmall {
          display: block;
        }
        a.navigate {
          color: $dacomyellow;
          top: $bunderHero-sm/2;
        }
        button.btn-favorite {
          top: $bunderHero-sm/1.5;
        }

        dl {
          position: absolute;
          padding-left: $gutter / 2;
          padding-right: $gutter / 2;
          color: $white;
          top: auto;
          bottom: 10px;
          z-index: 100;
        }
      }
      .loading {
        text-align: center;
        padding-top: 15px;
      }

      &:after, &:before {
        display: none;
      }
    }

    #section_growth {
      .keymap p {
        padding-left: 10px;
      }
      .bb_download {
        float:right;
        display: inline-block;
      }
      .bb_subscriptionFeature {
        cursor: pointer;
        float:right;
      }
    }

    #section_height {
      .bb_subscriptionFeature {
        cursor: pointer;
        float:right;
        line-height: 30px;
        padding-right: 20px;
      }
      .bb_download {
        float:right;
        display: inline-block;
      }
    }
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: map-get($grid-breakpoints, "lg")) {
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: map-get($grid-breakpoints, "xl")) {
}